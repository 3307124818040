var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    { staticClass: "my-10", attrs: { justify: "center" } },
    [
      _c("v-col", { attrs: { cols: "10" } }, [
        _c(
          "div",
          { staticStyle: { display: "flex", "justify-content": "flex-end" } },
          [
            _c("send-email-button", {
              attrs: {
                duct: _vm.duct,
                wids: _vm.workerIds,
                "selected-wids": _vm.selectedWorkerIds
              }
            }),
            _c("grant-qual-button", {
              attrs: {
                duct: _vm.duct,
                wids: _vm.workerIds,
                "selected-wids": _vm.selectedWorkerIds
              }
            })
          ],
          1
        )
      ]),
      _c(
        "v-col",
        { attrs: { cols: "10" } },
        [
          _c("v-data-table", {
            staticClass: "elevation-1",
            attrs: {
              dense: "",
              "show-select": "",
              "sort-desc": "",
              headers: _vm.headers,
              items: _vm.workers,
              "item-key": "wid",
              loading: _vm.loading,
              "sort-by": "Timestamp",
              search: _vm.search
            },
            scopedSlots: _vm._u([
              {
                key: "top",
                fn: function() {
                  return [
                    _c(
                      "v-card-title",
                      [
                        _vm._v(" Workers "),
                        _c(
                          "v-btn",
                          {
                            attrs: { icon: "" },
                            on: {
                              click: function($event) {
                                return _vm.listWorkers()
                              }
                            }
                          },
                          [_c("v-icon", [_vm._v("mdi-refresh")])],
                          1
                        ),
                        _c("v-spacer"),
                        _c("v-text-field", {
                          attrs: {
                            "single-line": "",
                            "hide-details": "",
                            "append-icon": "mdi-magnify",
                            label: "Search"
                          },
                          model: {
                            value: _vm.search,
                            callback: function($$v) {
                              _vm.search = $$v
                            },
                            expression: "search"
                          }
                        })
                      ],
                      1
                    )
                  ]
                },
                proxy: true
              },
              {
                key: "item.Projects",
                fn: function(ref) {
                  var item = ref.item
                  return [_vm._v(" " + _vm._s(item.Projects.join(", ")) + " ")]
                }
              }
            ]),
            model: {
              value: _vm.selectedWorkers,
              callback: function($$v) {
                _vm.selectedWorkers = $$v
              },
              expression: "selectedWorkers"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }